import React from 'react'
import styles from '../scss/circles.module.scss'

export default () => (
  <div className={styles.circles}>
    <div />
    <div />
    <div />
  </div>
)
