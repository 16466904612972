import React, { useState } from "react"
import { Link } from "gatsby"
import styles from "../scss/header.module.scss"
import starRed from "../content/images/star-24px-red.svg"
import "../scss/main.scss"

export default () => {
  const [menuStatus, menuChange] = useState(false)

  const ListLink = props => (
    <li className="nav-link">
      <Link activeClassName="current-page" to={props.to}>{props.children}</Link>
    </li>
  )

  const StarredListLink = props => (
    <ListLink style={{ position: 'relative' }} {...props}>
      <img style={{
        position: 'absolute',
        left: -12,
        height: '50%' 
      }} src={starRed} />
      {props.children}
    </ListLink>
  )

  return (
    <div className={`${menuStatus ? styles.menuOpen : ""}`}>
      <header className={styles.headerCont}>
        <div className={styles.logoCont}>
          <h2>
            <Link to="/">
              <span className={styles.brace}>&#123;</span>
              <span className="text-red">Atom</span>
              <span className={styles.ampersand}>&amp;</span>
              <span className="text-blue">Hannah</span>
              <span className={styles.brace}>&#125;</span>
            </Link>
          </h2>
        </div>
        <button className="btn" onClick={() => menuChange(!menuStatus)}>
          {!menuStatus ? "Menu" : "Back"}
        </button>
        <nav className={styles.navCont}>
          <ul>
            <li className="nav-link">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSdzv2CYjQC9FLlxEf5V_hAWH9aTupHey5gt0sPH7lUeNlq66w/viewform?usp=sf_link" target="_blank">
                RSVP
              </a>
            </li>
            <StarredListLink to="/covid">Covid Considerations</StarredListLink>
            <ListLink to="/timeline">Schedule</ListLink>
            <ListLink to="/venue">Venue</ListLink>
            <ListLink to="/food">Food</ListLink>
            <ListLink to="/registry">Registry</ListLink>
          </ul>
        </nav>
      </header>
    </div>
  )
}
